/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from 'elements/Button';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <div className="flex items-center">
        <img src="/logo192.png" alt="M & M IT Solutions Logo" className="h-10 w-10 mr-2" />
        <p className="text-4xl" style={{ fontFamily: 'Oswald, sans-serif' }}>
          <span style={{ color: '#000000' }}>M & M</span>
          <span style={{ color: '#6C63FF' }}> IT Solutions</span>
        </p>
      </div>

    </Button>
  );
}
